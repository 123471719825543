import { useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'reactstrap';
import { Loader } from '../../../components';
import { getText } from '../../../i18n/Text';
import { moduleDetailUrl } from '../../../modules/ModuleDetail/ModuleDetail';
import { getPeriodService } from '../../../periods/PeriodService';
import Reference, {
  displayName,
  ReferenceType
} from '../../../references/Reference';
import { useReferences } from '../../../references/useReferences';
import { getCredits } from '../../../types/canonical/Credits';
import { getDefaultCode } from '../../../types/canonical/Data';
import Group from '../../../types/canonical/Group';
import { getPeriodsDisplayName } from '../../../types/canonical/Offering';
import {
  loadModuleRows,
  ModuleRow,
  sortModules
} from '../../../types/SimpleModule';
import { WidgetField } from '../../../widgets/Widget';
import { CustomItem } from '../../../widgets/WidgetItem/components/CustomItem';
import { setGroup } from '../../GroupStructure/structureSlice';
import {
  StructureStoreDispatch,
  StructureStoreReturnType
} from '../../GroupStructure/structureStore';

type Props = {
  group: Group;
  year: string;
  fields: WidgetField[];
  isRoot?: boolean;
};

function ModuleLink({ row }: { row: ModuleRow }) {
  if (row.module && row.module.data) {
    return (
      <a href={moduleDetailUrl(row.module.data)}>{row.module.data.code}</a>
    );
  }
  return <></>;
}

function ModuleCell({
  row,
  header,
  year,
  phases,
  timeBlocks
}: {
  row: ModuleRow;
  header: WidgetField;
  year: string;
  phases: Reference[];
  timeBlocks: Reference[];
}) {
  switch (header.name) {
    case 'credits':
      return `${getCredits(row.module?.data.credits)}`;
    case 'description':
      return row.structure.description || '';
    case 'offerings':
      return getPeriodsDisplayName(row.structure.offerings, timeBlocks).join(
        ', '
      );
    case 'phase':
      return displayName(row.structure.phase, phases, row.structure.phase);
    default:
      return (
        <CustomItem
          field={header}
          values={row.structure.values}
          year={year}
          type="STRUCTURE"
        />
      );
  }
}

export function GroupModulesTable({
  group,
  year,
  fields,
  isRoot = false
}: Readonly<Props>) {
  const { t } = useTranslation('module');
  const { references: timeBlocks } = useReferences(
    ReferenceType.TIME_BLOCK,
    year
  );
  const { references: phases } = useReferences(ReferenceType.PHASE, year);
  const periods = getPeriodService().getPeriods(year);
  const ensuredId = getDefaultCode(group);
  const dispatch: StructureStoreDispatch = useDispatch();

  const groupSettings = useSelector(
    (state: StructureStoreReturnType) => state.groups[ensuredId]
  );

  const state = useQuery(
    [
      'groupModules',
      {
        groupUid: group.uid,
        yearId: group.year.id
      }
    ],
    () => loadModuleRows(group),
    { enabled: groupSettings.isFetched || groupSettings.isExpanded || isRoot }
  );

  // set "isFetched" to group in store, this allows us to keep te useQuery state enabled.
  useEffect(() => {
    if (!state.isFetched) return;
    dispatch(setGroup({ code: ensuredId, settings: { isFetched: true } }));
  }, [state.isFetched]);

  const headers = _(fields)
    .filter({ visible: true })
    .orderBy(['sequence', 'name'])
    .take(3)
    .value();

  return (
    <Loader state={state}>
      {(rows) => (
        <Table
          responsive
          striped
          size="sm"
          className="mb-0"
        >
          <thead>
            <tr>
              <th>{t('COLUMNS.CODE')}</th>
              <th>{t('COLUMNS.NAME')}</th>
              {headers.map((header, index) => (
                <th key={`header-${index}`}>
                  {getText(header.labels, header.name)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortModules(rows, phases, periods, timeBlocks).map(
              (row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className="text-truncate"
                >
                  <td>{<ModuleLink row={row} />}</td>
                  <td>{getText(row.module?.data.names)}</td>
                  {headers.map((header, headerIndex) => (
                    <td key={`row-${rowIndex}-${headerIndex}`}>
                      {
                        <ModuleCell
                          row={row}
                          year={year}
                          header={header}
                          phases={phases}
                          timeBlocks={timeBlocks}
                        />
                      }
                    </td>
                  ))}
                </tr>
              )
            )}
          </tbody>
        </Table>
      )}
    </Loader>
  );
}
