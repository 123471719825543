import { addSuccess } from '@42.nl/react-flash-messages';
import { Url, urlBuilder, useQueryParams } from '@42.nl/react-url/lib';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import { AppFrame, Loader } from '../../components';
import { AdminButton } from '../../components/AdminButton/AdminButton';
import { toQueryParams } from '../../components/AppFrame/components/TopBar/components/MainTabs/MainTabs';
import { PrintButton } from '../../components/Print/PrintButton';
import { LanguageLabel } from '../../i18n';
import { ProductPrint } from '../../product/ProductPrint';
import { toStudiesApp } from '../../studies/StudyLinks';
import { useActiveMainTab } from '../../tabs/context/hooks/useActiveMainTab';
import { useSubTabs } from '../../tabs/context/hooks/useSubTabs';
import { getActiveSubTab } from '../../tabs/context/utils/SubTabUtils';
import SubTabsNav from '../../tabs/SubTabsNav';
import Module from '../../types/canonical/Module';
import { MODULE_APP_URL, toModulesApp } from '../../types/ModuleLinks';
import {
  ProductDetailPathParams,
  ProductDetailQueryParams,
  useProductQuery
} from '../../types/Product';
import SimpleModule from '../../types/SimpleModule';
import { WidgetButtons } from '../../widgets/WidgetDetail/WidgetButtons';
import { WidgetDetail } from '../../widgets/WidgetDetail/WidgetDetail';
import ProductDetailYearDropdown from '../../years/components/YearDropdown/ProductDetailYearDropdown';
import { defaultModuleDetailQueryParams } from './ModuleDetailQueryParams';

export async function loadModule(
  code: string,
  queryParams: ProductDetailQueryParams
) {
  return SimpleModule.one(code, queryParams);
}

export default function ModuleDetail() {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useQueryParams<ProductDetailQueryParams>({
    location,
    defaultQueryParams: defaultModuleDetailQueryParams(),
    debugName: 'ModuleDetail'
  });

  const { code = '' } = useParams<ProductDetailPathParams>();
  const { t } = useTranslation(['translation', 'module', 'import']);

  const state = useProductQuery<SimpleModule>({
    productType: 'module',
    loadFunction: loadModule,
    code,
    queryParams,
    filterChanged
  });

  const activeMainTab = useActiveMainTab();
  const subTabs = useSubTabs();
  const activeSubTab = getActiveSubTab(subTabs, queryParams.tab);

  function filterChanged(name: string, value: string | number) {
    navigate(toModuleDetail({ code }, { ...queryParams, [name]: value }), {
      replace: true
    });
  }

  async function importModule(module: SimpleModule) {
    try {
      await SimpleModule.import(module);
      addSuccess({
        text: t('import:STARTED_MESSAGE')
      });
    } catch (error) {
      /* empty */
    }
  }

  return (
    <Loader
      state={state}
      isEmpty={(data) => data?.id === undefined}
      emptyContent={() => (
        <Navigate
          to={toStudiesApp(toQueryParams(activeMainTab, queryParams.year))}
          replace
        />
      )}
    >
      {(module) => (
        <AppFrame
          breadcrumbs={
            <Breadcrumb>
              <BreadcrumbItem>
                <Link
                  to={toModulesApp(
                    toQueryParams(activeMainTab, module.data.year.id)
                  )}
                >
                  {t('module:MODULES')}
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {module.data.code?.toUpperCase()}
              </BreadcrumbItem>
            </Breadcrumb>
          }
        >
          <Row className="justify-content-center">
            <Col
              md={12}
              lg={12}
              xs={12}
              className="details__content__main me-auto"
            >
              <h1 className="details__content__main-title">
                <span className="pe-1">({module.data.code})</span>
                <LanguageLabel names={module.data.names} />
              </h1>

              <Row
                className="mb-3"
                style={{ minHeight: '46px' }}
              >
                <Col
                  m={12}
                  md={12}
                  lg={12}
                  xs={12}
                >
                  <ProductDetailYearDropdown
                    yearExternalId={queryParams.year}
                    productCode={module.data.code}
                    productYearsPromise={SimpleModule.years}
                    onChange={filterChanged}
                  />

                  <WidgetButtons product={module}>
                    <AdminButton
                      label={t('import:BUTTON')}
                      onClick={() => importModule(module)}
                    />

                    <PrintButton>
                      <ProductPrint
                        product={module}
                        tabs={subTabs}
                      />
                    </PrintButton>
                  </WidgetButtons>
                </Col>
              </Row>

              <SubTabsNav
                subTabs={subTabs}
                activeTabName={activeSubTab?.name}
                product={state.data}
                toggle={filterChanged}
              />

              <TabContent activeTab={activeSubTab?.name}>
                {subTabs.map((subTab) => (
                  <TabPane
                    tabId={subTab.name}
                    key={subTab.id}
                  >
                    <WidgetDetail
                      tabId={subTab.id}
                      product={module}
                      expanded={false}
                    />
                  </TabPane>
                ))}
              </TabContent>
            </Col>
          </Row>
        </AppFrame>
      )}
    </Loader>
  );
}

export const MODULE_DETAIL_URL = `${MODULE_APP_URL}/:code`;

function toModuleDetail(
  pathParams: ProductDetailPathParams,
  queryParams: ProductDetailQueryParams
): Url {
  return urlBuilder({
    url: MODULE_DETAIL_URL,
    pathParams: pathParams,
    queryParams: queryParams,
    defaultQueryParams: defaultModuleDetailQueryParams()
  });
}

export function moduleDetailUrl({
  id,
  uid,
  code,
  year,
  mainTab
}: {
  mainTab?: string;
} & Module) {
  return toModuleDetail(
    {
      code: code || id || uid
    },
    {
      year: `${year.id}`,
      mainTab
    }
  );
}
